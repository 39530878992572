import styled from 'styled-components'

export const ChangeCardPinWrapper = styled.div`
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .change-card-pin-form {
    width: 100%;
    padding-top: 4rem;
  }

  .change-card-pin-title {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 100%;
    margin-bottom: 1.6rem;
  }

  .core-input-wrapper {
    margin-bottom: 1.6rem;

    &:last-of-type {
      margin-bottom: 7.2rem;
    }
  }

  .change-card-pin-loader {
    position: relative;
  }
`
export const ReplaceCardWrapper = styled.div`
  padding: 1.2rem;
  .replace-card-text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    padding: 4rem 0 7.2rem;
  }
`
export const UnfreezeCardWrapper = styled.div`
  padding: 1.2rem;
  .unfreeze-card-text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    padding: 4rem 0 7.2rem;
  }
`

export const FreezeCardWrapper = styled.div`
  padding: 1.2rem;
  .unfreeze-card-text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    padding: 4rem 0 7.2rem;
  }
`
export const TerminateCardWrapper = styled.div`
  padding: 4rem;

  .title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 150%;
    color: #131e3d;
    display: flex;
    justify-content: center;
  }

  .terminate-card-text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    color: #2b313a;
    padding: 4rem 0 7.2rem;
    text-align: center;
  }

  .cancel-button {
    margin-top: 1rem;
  }
`
export const StatementHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 4rem;

  .title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 150%;
    text-align: center;
  }

  .statement-history-element {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    border-radius: 1.6rem;
    border: 1px solid #dcdfe5;
    background-color: white;
    cursor: pointer;
    gap: 0.4rem;

    .statement-history-period {
      .statement-history-title {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.68rem;
        color: #131e3d;
        padding-bottom: 0.4rem;
      }

      .statement-history-period-value {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #8f96a3;
      }
    }
  }
`

export const AdvancedCardOptionsWrapper = styled.div`
  padding: 4rem;

  .title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 150%;
    color: #131e3d;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
  }

  .advanced-card-options-buttons {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding-bottom: 4rem;

    .card-settings-item-wrapper {
      background-color: white;
      padding: 1.6rem;
      cursor: auto;
      border: 1px solid #d8d9dc;
      border-radius: 1.6rem;

      .switch-wrapper {
        cursor: pointer;
      }
    }
  }
  .advanced-card-options-submit {
    padding-top: 3.2rem;
    display: grid;
    gap: 1rem;
  }
`

export const SpendFromModalWrapper = styled.div`
  .spend-from-modal-main {
    border-radius: 1.6rem;
    padding: 4rem;

    .spend-from-title {
      font-size: 2.4rem;
      text-align: center;
    }

    .spend-from-header {
      padding: 0.8rem;
    }

    label {
      .check-box-component {
        margin-right: 0.6rem;
      }
      p {
        user-select: none;
      }
    }
    .spend-from-account-list {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      margin-top: 1.6rem;

      .spend-from-account-element {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        padding: 1.2rem;
        border-radius: 1.6rem;
        min-height: 6rem;
        border-radius: 0.8rem;
        border: 1px solid #2c2c2c4d;

        input:disabled + i {
          background: rgb(244, 246, 246);
        }

        input:checked + i {
          background-color: #3dd394;
        }

        input:checked + i::after {
          transform: translate3d(calc(-4px + 2.5rem), 2px, 0px);
        }

        input:checked + i::before {
          background: transparent;
        }
      }

      .currency-account-left-section {
        display: flex;
        gap: 0.6rem;
        align-items: center;

        .account-name {
          font-size: 1.4rem;
          line-height: 100%;
          font-weight: 700;
          margin-bottom: 0.4rem;
        }

        .account-balance {
          font-size: 1.2rem;
          line-height: 100%;
          font-weight: 400;
        }

        .balance-label {
          opacity: 0.5;
        }
      }
    }
  }
  .spend-from-modal-bottom {
    padding: 4rem;
    padding-top: 0;
  }
`
