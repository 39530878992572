import styled from 'styled-components'

export const ActivateCardWrapper = styled.div`
  padding: 1.2rem;
  .activate-card-text {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.08rem;
    padding: 1.6rem 0;
  }

  .core-input-wrapper {
    margin-bottom: 7.2rem;
  }
`
