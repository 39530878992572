import styled from 'styled-components'

import { breakpoints } from '../../constants'
import { CommonFlexColumn, CommonFlexRow } from '../jetonStyled'
import HamburgerMenuIcon from '@images/icons/hamburger-menu.svg'
import RightIcon from '@images/icons/arrow-right-white.svg'

export const HeaderWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-template-columns: repeat(2, 1fr);
  margin: 0;

  width: 100%;
  height: 100px;
  background-color: #fff;

  .overlay {
    z-index: 10;
    background: rgba(96, 96, 96, 0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    white-space: nowrap;
  }

  .header-left {
    ${CommonFlexRow()}
    justify-content: space-between;
  }

  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr;
    height: fit-content;
    width: 100%;
  }
`

export const HeaderContainer = styled.nav`
  ${CommonFlexRow()}
  align-items: center;
  justify-content: initial;
  gap: 2rem;
  .brand-logo {
    display: block;
    text-align: inherit;
  }

  @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const HeaderInnerContainer = styled.div<{ bordered?: boolean }>`
  ${CommonFlexRow()}
  justify-self: flex-end;

  @media (max-width: 400px) {
    ${CommonFlexColumn()}
  }

  @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
    border-top: ${props => (props.bordered ? '1px solid #e2e4e9' : 'none')};
    & > * {
      border-bottom: ${props => (props.bordered ? '1px solid #e2e4e9' : 'none')};
    }
  }
`

export const HeaderIconsWrapper = styled.div`
  ${CommonFlexRow(1.5)}
  align-items: center;
  padding: 0 1rem;
  position: relative;
  border-left: 1px solid #e2e4e9;
  height: 100%;
  flex: 2;
  svg {
    cursor: pointer;
  }
  .settings-wrapper {
    position: relative;
  }
`

export const ActivityButton = styled.div`
  flex: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2rem;

  gap: 1.5rem;

  color: #131e3d;

  border: 1px solid #e9e9e9;
  border-radius: 10px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  :hover {
    border: 1px solid #8f96a3;
  }
`

export const Hamburger = styled.div`
  padding: 2rem;

  div.title {
    color: #131e3d;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    //prettier-ignore
    background: url("${HamburgerMenuIcon}") center center no-repeat;
  }
`
export const HamburgerMenuWrapper = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  padding: 2rem 2rem 4rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  ${CommonFlexColumn(2)}

  background: #ffffff;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  .upper {
    ${CommonFlexRow(2)}
    justify-content: space-between;
    align-items: center;
    a {
      padding: 0;
      img {
        padding: 0 !important;
      }
    }
  }
  .items-container {
    flex: 1;
    margin-top: 2rem;
    width: 100%;
    ${CommonFlexColumn(1)};
    a {
      ${CommonFlexRow()}
      justify-content: space-between;

      width: 100%;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.2rem;
      height: auto;
      text-decoration: none;
      border-bottom: 1px solid rgba(237, 238, 239, 1);

      &::after {
        content: ' ';
        //prettier-ignore
        background: url("${RightIcon}") center center no-repeat;
        width: 15px;
        height: 10px;
      }
    }
  }
  .action-button {
    flex: 0;
    button {
      background-color: #06f;
      color: #ffffff;
      font-weight: 400;
    }
  }
`
