import { createContext, useState } from 'react'

import { breakpoints } from '../../../constants'
import { useWindowSize } from 'mmfintech-commons'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { OnboardingBannerMobile } from './OnboardingBannerMobile'
import { OnboardingBannerDesktop } from './OnboardingBannerDesktop'

import { MerchantAccountTypeEnum, OnboardingProviderEnum } from 'mmfintech-commons-types'

export const SumSubContext = createContext({
  setIsVisible: null,
  isVisible: false
})

export const OnboardingBanner = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingProvider } = merchant || {}

  const [width] = useWindowSize()
  const [isVisible, setIsVisible] = useState(false)

  const isMobile = width < breakpoints.xl

  if (
    onboardingProvider === OnboardingProviderEnum.SUMSUB &&
    accountType === MerchantAccountTypeEnum.PROSPECT
  ) {
    return (
      <SumSubContext.Provider value={{ setIsVisible, isVisible }}>
        {isMobile ? <OnboardingBannerMobile /> : <OnboardingBannerDesktop />}
      </SumSubContext.Provider>
    )
  }

  return null
}
