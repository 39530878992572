import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import {
  paths,
  responseDoesNotContainsChallenge,
  useCardsSupportedQry,
  useChange3dsPhoneMutation,
  useGetMerchantQuery,
  useGetPrepaidCardsQuery,
  useGetProfileQuery,
  useIssuePrepaidCardMutation
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  OtpContext,
  fixPhoneNumber,
  isValidArray,
  tr,
  useFormValues,
  isEmptyString,
  isNotEmptyString,
  isValidObject
} from 'mmfintech-commons'

import { Stepper } from '../'
import { CardBrandLogo } from './CardBrandLogo'
import { CardOrderForm } from './CardOrderForm'
import { CardBrandButton } from './CardBrandButton'
import { CardOrderSuccess } from './CardOrderSuccess'
import { Button, Checkbox, ErrorDisplay, Input, Spinner } from 'mmfintech-portal-commons'
import { CardBrandsWrapper, CardOrderModalWrapper, CardTypeContainer, CardTypeWrapper } from './CardOrderModal.styled'

import { CardBrand, MerchantEntityTypeEnum } from 'mmfintech-commons-types'

import VirtualCardIcon from '../../images/virtual-card-without-brand.svg?react'
import PhysicalCardIcon from '../../images/physical-card-without-brand.svg?react'

const MAX_STEPS = [
  { step: 1, label: 'Card Type' },
  { step: 2, label: 'Contact' },
  { step: 3, label: 'Delivery' }
]

enum CardType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL'
}

interface CardOrderModalProps {
  currentStep?: number
  selectedCardType?: CardType
  selectedCardBrand?: CardBrand
  selectedCurrency?: string
  large?: boolean

  issueCard?: boolean
  passedData?: any
  error?: any
}

export const CardOrderModal = (props: CardOrderModalProps) => {
  const { currentStep, selectedCardType, selectedCardBrand, selectedCurrency, issueCard, passedData, error } = props

  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalHide, modalShow } = useContext(GlobalContext)

  const [step, setStep] = useState<number>(currentStep || 1)

  const [cardType, setCardType] = useState<CardType>(selectedCardType)
  const [virtualCardBrand, setVirtualCardBrand] = useState<CardBrand>(
    selectedCardType === CardType.VIRTUAL ? selectedCardBrand : null
  )
  const [physicalCardBrand, setPhysicalCardBrand] = useState<CardBrand>(
    selectedCardType === CardType.PHYSICAL ? selectedCardBrand : null
  )

  const [currencyCode, setCurrencyCode] = useState<string>(selectedCurrency)
  const [bootstrapError, setBootstrapError] = useState(null)
  const [capabilityError, setCapabilityError] = useState(null)

  const history = useHistory()
  const { data: merchant } = useGetMerchantQuery()
  const { entityType, capabilities } = merchant || {}
  const { enableCardMultispending } = (capabilities as any) || {}

  const { data: profile, error: getProfileError, isLoading: getProfileLoading } = useGetProfileQuery(null)
  const { isCardIssuingEnabled } = profile || {}

  const phoneValues = useFormValues({
    phoneNumber: { required: true, validation: 'phone' }
  })

  const formValues = useFormValues({
    countryCode: { required: true },
    city: { required: true, validation: 'alphaNumeric+' },
    street: { required: true, validation: 'alphaNumeric+' },
    streetNumber: { required: true, validation: 'alphaNumeric+' },
    postalCode: { required: true, validation: 'safe-string' },
    companyName: { required: true, validation: 'safe-string' },
    firstName: { required: true, validation: 'safe-string' },
    lastName: { required: true, validation: 'safe-string' },
    region: { validation: 'safe-string', maxLength: 50 }
  })

  const [changePhone, { error: changePhoneError, isLoading: changePhoneLoading }] = useChange3dsPhoneMutation()
  const [orderCard, { error: cardIssueError, isLoading: cardIssueLoading }] = useIssuePrepaidCardMutation()

  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetPrepaidCardsQuery(null)
  const hasPhysicalCard = (): boolean => isValidArray(activeCards) && activeCards.some(card => !card.isVirtual)

  const {
    findCurrencies,
    supportedCards,
    supportedPhysical,
    supportedVirtual,
    supportedCardsError,
    supportedCardsLoading
  } = useCardsSupportedQry()

  const {
    phone2fa,
    phone3ds,
    phone3dsVerified,
    phoneStatusChecked,
    phoneStatusLoading,
    refetch: recheckPhoneStatus
  } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isSuccess, isLoading }) => {
      return {
        phone2fa: data?.twoFactorPhone,
        phone3ds: data?.phone3ds,
        phone3dsVerified: !!data?.phone3dsVerified,
        phoneStatusChecked: isSuccess,
        phoneStatusLoading: isLoading
      }
    }
  })

  const handleSelectPhysical = () =>
    cardType !== CardType.PHYSICAL &&
    isValidArray(supportedPhysical) &&
    !hasPhysicalCard() &&
    setCardType(CardType.PHYSICAL)

  const handleSelectVirtual = () =>
    cardType !== CardType.VIRTUAL && isValidArray(supportedVirtual) && setCardType(CardType.VIRTUAL)

  const reopenModalAgain = (nextStep: number, rest?: any) => {
    setTimeout(() => {
      modalShow({
        content: (
          <CardOrderModal
            currentStep={nextStep}
            selectedCardType={cardType}
            selectedCardBrand={cardType === CardType.VIRTUAL ? virtualCardBrand : physicalCardBrand}
            selectedCurrency={currencyCode}
            {...rest}
          />
        ),
        options: { size: 'auto' }
      })
    }, 0)
  }

  const initiatePhoneUpdate = async () => {
    if (!phoneValues.areValid()) return

    const phoneUpdateSuccess = () => {
      reopenModalAgain(2, {
        issueCard: true,
        passedData: formValues.prepare()
      })
    }

    setOtpOnSuccess(() => phoneUpdateSuccess)

    try {
      const phoneNumber = phoneValues.getValue('phoneNumber')
      const response = await changePhone({ phone: fixPhoneNumber(phoneNumber) }).unwrap()
      if (responseDoesNotContainsChallenge(response)) {
        await recheckPhoneStatus().unwrap()
        void phoneUpdateSuccess()
      }
    } catch {}
  }

  const initiateCardIssuing = async () => {
    const cardIssueResponse = (response: any) => {
      const { externalCardId } = response || {}
      reopenModalAgain(externalCardId ? 3 : 1, {
        error: !externalCardId ? tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed') : null,
        selectedCardType: cardType,
        selectedCardBrand: cardType === CardType.VIRTUAL ? virtualCardBrand : physicalCardBrand,
        selectedCurrency: currencyCode
      })
    }

    const data = {
      isVirtual: cardType === CardType.VIRTUAL,
      cardBrand: cardType === CardType.VIRTUAL ? virtualCardBrand : physicalCardBrand,
      currencyCode,
      ...(cardType === CardType.PHYSICAL
        ? {
            address: {
              country: formValues.getValue('countryCode'),
              streetName: formValues.getValue('street'),
              streetNumber: formValues.getValue('streetNumber'),
              zipCode: formValues.getValue('postalCode'),
              city: formValues.getValue('city'),
              ...(physicalCardBrand === 'VISA' ? { region: formValues.getValue('region') } : {})
            }
          }
        : null),
      ...(entityType === MerchantEntityTypeEnum.COMPANY
        ? {
            companyCardDetails: {
              companyName: formValues.getValue('companyName'),
              firstName: formValues.getValue('firstName'),
              lastName: formValues.getValue('lastName')
            }
          }
        : null)
    }

    setOtpOnSuccess(() => (response: any) => cardIssueResponse(response))

    try {
      const response = await orderCard(data).unwrap()
      if (responseDoesNotContainsChallenge(response)) {
        cardIssueResponse(response)
      }
    } catch {}
  }

  const handleContinueToStep2 = () => {
    if (!isCardIssuingEnabled) {
      return setCapabilityError(
        tr(
          'FRONTEND.DASHBOARD.CARDS_ORDER.CAPABILITY_ERROR',
          'Owner user should allow card issuing for your user, before you can issue a card'
        )
      )
    }
    setStep(2)
    if (phone3dsVerified && cardType === CardType.VIRTUAL && entityType !== MerchantEntityTypeEnum.COMPANY) {
      void initiateCardIssuing()
    }
  }

  const handleContinueToStep3 = () => {
    if (cardType === CardType.PHYSICAL && !formValues.areValid()) {
      return
    }

    if (phone3dsVerified) {
      void initiateCardIssuing()
    } else {
      void initiatePhoneUpdate()
    }
  }

  const handleContinueToOverview = () => {
    history.push(paths.dashboard())
    modalHide()
  }

  useEffect(() => {
    if (cardIssueError) {
      formValues.handleErrors(cardIssueError)
    }
  }, [cardIssueError])

  useEffect(() => {
    if (phoneStatusChecked && isEmptyString(phone3ds) && isNotEmptyString(phone2fa)) {
      phoneValues.setValue('phoneNumber', phone2fa)
    }
  }, [phoneStatusChecked])

  useEffect(() => {
    if (!cardType && !!supportedCards) {
      if (isValidArray(supportedPhysical) && !isValidArray(supportedVirtual)) {
        setCardType(CardType.PHYSICAL)
      } else if (!isValidArray(supportedPhysical) && isValidArray(supportedVirtual)) {
        setCardType(CardType.VIRTUAL)
      }
    }
  }, [supportedCards])

  useEffect(() => {
    if (selectedCardType && selectedCardBrand) {
      if (selectedCardType === CardType.PHYSICAL) {
        return setPhysicalCardBrand(selectedCardBrand)
      }
      if (selectedCardType === CardType.VIRTUAL) {
        return setVirtualCardBrand(selectedCardBrand)
      }
    }
    if (
      (cardType === CardType.PHYSICAL && supportedPhysical.includes(physicalCardBrand)) ||
      (cardType === CardType.VIRTUAL && supportedVirtual.includes(virtualCardBrand))
    ) {
      return
    }

    if (cardType === CardType.PHYSICAL) {
      if (Array.isArray(supportedPhysical) && supportedPhysical.length === 1) {
        setPhysicalCardBrand(supportedPhysical[0])
        return
      }
    } else if (cardType === CardType.VIRTUAL) {
      if (Array.isArray(supportedVirtual) && supportedVirtual.length === 1) {
        setVirtualCardBrand(supportedVirtual[0])
        return
      }
    }

    setVirtualCardBrand(null)
    setPhysicalCardBrand(null)
  }, [cardType])

  useEffect(() => {
    if (selectedCurrency) return setCurrencyCode(selectedCurrency)
    const cardBrand = cardType === CardType.VIRTUAL ? virtualCardBrand : physicalCardBrand
    if (cardBrand && isValidObject(supportedCards)) {
      const currencies = findCurrencies(cardType, cardBrand)
      if (isValidArray(currencies)) {
        if (currencyCode && currencies.includes(currencyCode)) {
          return
        } else {
          // THE CURRENCIES SHOULD NOT HAVE MORE THAN ONE OPTION
          // BUT JUST IN CASE SELECT THE FIRST ONE
          setCurrencyCode(currencies[0])
          return
        }
      }
    }
    setCurrencyCode(null)
  }, [cardType, virtualCardBrand, physicalCardBrand, supportedCards])

  useEffect(() => {
    if (isValidObject(passedData)) {
      const { countryCode, city, street, streetNumber, postalCode, firstName, lastName, companyName } = passedData
      formValues.setValue('countryCode', countryCode)
      formValues.setValue('city', city)
      formValues.setValue('street', street)
      formValues.setValue('streetNumber', streetNumber)
      formValues.setValue('postalCode', postalCode)
      formValues.setValue('firstName', firstName)
      formValues.setValue('lastName', lastName)
      formValues.setValue('companyName', companyName)
    }
    if (issueCard) {
      if (!phone3dsVerified) {
        void recheckPhoneStatus().unwrap()
      } else {
        handleContinueToStep3()
      }
    }
  }, [issueCard, passedData])

  useEffect(() => {
    if (phone3dsVerified && issueCard) {
      handleContinueToStep3()
    }
  }, [phone3dsVerified])

  return (
    <CardOrderModalWrapper large={props?.large}>
      {activeCardsLoading || supportedCardsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='card-order-header-wrapper'>
            <span className='card-order-header-title'>{tr('FRONTEND.CARDS.ORDER.TITLE', 'Order a Card')}</span>
            <div className='card-order-stepper'>
              <Stepper currentStep={step} maxStep={MAX_STEPS} />
            </div>
          </div>

          <ErrorDisplay error={[activeCardsError, supportedCardsError, error, capabilityError, getProfileError]} />

          {step === 1 && (
            <>
              <div className='card-order-type-selection'>
                <CardTypeWrapper
                  className={cn({
                    selected: cardType === CardType.PHYSICAL,
                    unavailable: !isValidArray(supportedPhysical)
                  })}>
                  <CardTypeContainer onClick={handleSelectPhysical}>
                    <div className='card-order-card-icon'>
                      <PhysicalCardIcon />
                      {cardType === CardType.PHYSICAL && (
                        <div className='card-brand-icon'>
                          <CardBrandLogo brand={physicalCardBrand} logoOnly />
                        </div>
                      )}
                    </div>
                    <div className='card-order-card-content'>
                      <div className='card-order-card-title'>
                        {tr('FRONTEND.CARDS.ORDER.PHYSICAL.TITLE', 'Physical card')}
                      </div>
                      <div className='card-order-card-text'>
                        {enableCardMultispending
                          ? tr(
                              'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT_WITH_MULTI_CURRENCIES',
                              `A contactless debit card will be delivered to you. Card will be allowed to spend from all currency accounts. If you want to change this, go to card options after the card is issued and adjust.`
                            )
                          : tr(
                              'FRONTEND.CARDS.ORDER.PHYSICAL.TEXT1',
                              'A contactless debit card will be delivered to you.'
                            )}
                        {/* {cardType === CardType.PHYSICAL && !!currencyCode && (
                          <>
                            {tr(
                              'FRONTEND.CARDS.ORDER.PHYSICAL.TEXT2',
                              'Your card balance will be in {{CURRENCY}} with a new account.',
                              { CURRENCY: currencyCode }
                            )}
                          </>
                        )} */}
                      </div>
                    </div>
                    {isValidArray(supportedPhysical) && (
                      <div className='card-order-check-icon'>
                        <Checkbox
                          className='card-order-checkbox'
                          checked={cardType === CardType.PHYSICAL}
                          onClick={handleSelectPhysical}
                        />
                      </div>
                    )}
                  </CardTypeContainer>

                  {isValidArray(supportedPhysical) &&
                    supportedPhysical.length > 1 &&
                    cardType === CardType.PHYSICAL && (
                      <CardBrandsWrapper>
                        {supportedPhysical.map(brand => (
                          <CardBrandButton
                            key={`physical-${brand}`}
                            brand={brand}
                            selected={physicalCardBrand === brand}
                            onClick={() => setPhysicalCardBrand(brand)}
                          />
                        ))}
                      </CardBrandsWrapper>
                    )}
                </CardTypeWrapper>

                <CardTypeWrapper
                  className={cn({
                    selected: cardType === CardType.VIRTUAL,
                    unavailable: !isValidArray(supportedVirtual)
                  })}>
                  <CardTypeContainer onClick={handleSelectVirtual}>
                    <div className='card-order-card-icon'>
                      <VirtualCardIcon />
                      {cardType === CardType.VIRTUAL && (
                        <div className='card-brand-icon'>
                          <CardBrandLogo brand={virtualCardBrand} logoOnly />
                        </div>
                      )}
                    </div>
                    <div className='card-order-card-content'>
                      <div className='card-order-card-title'>
                        {tr('FRONTEND.CARDS.ORDER.VIRTUAL.TITLE', 'Virtual card')}
                      </div>
                      <div className='card-order-card-text'>
                        {enableCardMultispending
                          ? tr(
                              'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT_WITH_MULTI_CURRENCIES',
                              `Get virtual card and manage your online payments. Card will be allowed to spend from all currency accounts. If you want to change this, go to card options after the card is issued and adjust.`
                            )
                          : tr(
                              'FRONTEND.CARDS.ORDER.VIRTUAL.TEXT1',
                              'Get virtual card and manage your online payments.'
                            )}
                        {/* {cardType === CardType.VIRTUAL && !!currencyCode && (
                          <>
                            {tr(
                              'FRONTEND.CARDS.ORDER.VIRTUAL.TEXT2',
                              'Your card balance will be in {{CURRENCY}} with a new account.',
                              { CURRENCY: currencyCode }
                            )}
                          </>
                        )} */}
                      </div>
                    </div>
                    {isValidArray(supportedVirtual) && (
                      <div className='card-order-check-icon'>
                        <Checkbox
                          className='card-order-checkbox'
                          checked={cardType === CardType.VIRTUAL}
                          onClick={handleSelectVirtual}
                        />
                      </div>
                    )}
                  </CardTypeContainer>

                  {isValidArray(supportedVirtual) && supportedVirtual.length > 1 && cardType === CardType.VIRTUAL && (
                    <CardBrandsWrapper>
                      {supportedVirtual.map(brand => (
                        <CardBrandButton
                          key={`virtual-${brand}`}
                          brand={brand}
                          selected={virtualCardBrand === brand}
                          onClick={() => setVirtualCardBrand(brand)}
                        />
                      ))}
                    </CardBrandsWrapper>
                  )}
                </CardTypeWrapper>
              </div>

              <div className='card-order-modal-buttons-wrapper'>
                <Button
                  color='primary'
                  disabled={!cardType || !!getProfileError}
                  text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
                  onClick={handleContinueToStep2}
                  loading={getProfileLoading}
                />
                <Button color='secondary' text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')} onClick={modalHide} />
              </div>
            </>
          )}

          {step === 2 && (
            <>
              {phoneStatusChecked && !phone3dsVerified && (
                <div className='card-order-phone-section'>
                  <div className='card-order-phone-instructions'>
                    {tr(
                      'FRONTEND.CARDS.ORDER.PHONE_INSTRUCTIONS',
                      "Please enter your phone number. We'll send you a code via SMS"
                    )}
                  </div>

                  <Input
                    type='phone'
                    label={tr('FRONTEND.CARDS.ORDER.PHONE_NUMBER', 'Mobile number')}
                    {...phoneValues.registerInput('phoneNumber')}
                    autoComplete='off'
                  />
                </div>
              )}

              {
                <CardOrderForm
                  formValues={formValues}
                  cardType={cardType}
                  entityType={entityType}
                  setBootstrapError={setBootstrapError}
                  cardBrand={physicalCardBrand}
                />
              }

              {(phoneStatusLoading || changePhoneLoading || cardIssueLoading) && <Spinner />}
              {cardIssueLoading && (
                <div className='card-order-issuing-message'>
                  {tr('FRONTEND.CARDS.ORDER.ISSUING_MESSAGE', 'Your card is now being issued.')}
                </div>
              )}

              <div className='error-wrapper'>
                <ErrorDisplay error={[changePhoneError, cardIssueError]} />
              </div>

              <div className='card-order-modal-buttons-wrapper'>
                <Button
                  color='primary'
                  disabled={cardIssueLoading || bootstrapError}
                  text={tr('FRONTEND.CARDS.ORDER.COMPLETE_BUTTON', 'Accept and complete order')}
                  onClick={handleContinueToStep3}
                />
                <Button color='secondary' text={tr('FRONTEND.BUTTONS.BACK', 'Back')} onClick={() => setStep(1)} />
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <CardOrderSuccess type={cardType} />

              <div className='card-order-modal-buttons-wrapper'>
                <Button
                  color='primary'
                  loading={cardIssueLoading}
                  disabled={!cardType}
                  text={tr('FRONTEND.CARDS.ORDER.FINISH_BUTTON', 'Continue to Overview')}
                  onClick={handleContinueToOverview}
                />
              </div>
            </>
          )}
        </>
      )}
    </CardOrderModalWrapper>
  )
}
