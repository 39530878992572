import styled from 'styled-components'

export const WelcomeContent2Styled = styled.div`
  max-width: 652px;
  margin: 0 auto;

  .logo {
    margin-bottom: 4.8rem;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }

  .description {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: #000000;
  }
`
