import React from 'react'

import { tr } from 'mmfintech-commons'

import DoneIcon from '@images/icons/application-fee-paid.svg?react'
import ReviewIcon from '@images/icons/in-review-icon.svg?react'

export const ActionComponent: React.FC<{ isDone?: boolean }> = ({ isDone = true }) => (
  <div className='action-container'>
    {isDone ? <DoneIcon /> : <ReviewIcon />}
    <span>
      {isDone ? tr('FRONTEND.ONBOARDING.ACTION.DONE', 'Done') : tr('FRONTEND.ONBOARDING.ACTION.REVIEW', 'Review')}
    </span>
  </div>
)
