import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import parse from 'html-react-parser'

import { tr } from 'mmfintech-commons'
import { useOnboardingProgress } from '../../../hooks'

import { PillButton } from './OnboardingBanner.styled'
import { SumSubContext } from './OnboardingBanner'
import { AnimatePresence } from 'framer-motion'
import { OnboardingContainer } from './OnboardingContainer'

export const OnboardingBannerDesktop = () => {
  const { setIsVisible, isVisible } = useContext(SumSubContext)

  const [containerPosition, setContainerPosition] = useState({ bottom: '84px', right: '46px' })

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { pathname } = useLocation()
  const { progressFraction } = useOnboardingProgress()

  const updateContainerPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect()
      setContainerPosition({
        bottom: `calc(100vh - (${buttonRect.bottom - buttonRef.current.offsetHeight}px) + 10px)`,
        right: `calc(100vw - ${buttonRect.right}px)`
      })
    }
  }

  useEffect(() => {
    updateContainerPosition()
    window.addEventListener('resize', updateContainerPosition)

    return () => {
      window.removeEventListener('resize', updateContainerPosition)
    }
  }, [buttonRef])

  useEffect(() => {
    setIsVisible(false)
  }, [pathname])

  const toggleVisibility = () => setIsVisible(!isVisible)

  return (
    <>
      <PillButton
        ref={buttonRef}
        onClick={toggleVisibility}
        isActive={isVisible}
        title={tr(
          'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.MESSAGE',
          'Your document is expiring, upload newer one to avoid limitation of services'
        )}>
        <span>
          {parse(
            tr('FRONTEND.ONBOARDING.PROGRESS', 'Onboarding {{progressFraction}} complete', {
              progressFraction
            })
          )}
        </span>
      </PillButton>
      <AnimatePresence>
        {isVisible && (
          <OnboardingContainer
            toggleOpen={toggleVisibility}
            style={{ bottom: containerPosition.bottom, right: containerPosition.right }}
          />
        )}
      </AnimatePresence>
    </>
  )
}
