import SideBarInnerRight from '../SideBar/SideBarInnerRight'
import { Wrapper } from './ContentWrapper.styled'

export const ContentWrapper = ({ children, sideBarInner }) => {
  return (
    <Wrapper>
      <>{children}</>
      <SideBarInnerRight {...sideBarInner} />
    </Wrapper>
  )
}
