import { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import parse from 'html-react-parser'
import cn from 'classnames'

import {
  stakingPeriods,
  useCurrencies,
  useGetSavingPlansForCurrencyQuery,
  useInitiateStakingMutation,
  useMerchantAccounts
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  checkAmountLimits,
  fixFloat,
  formatDate,
  formatFloat,
  isValidArray,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import { Button, Checkbox, ErrorDisplay, GridTable, LabelValue } from 'mmfintech-portal-commons'

import {
  Duration,
  InvestInfoWrapper,
  InvestModalWrapper,
  ServiceAgreement,
  StyledForm
} from './styled/investModal.styled'
import SelectAccount from '../../components/SelectAccount'
import InputAmount from '../../components/InputAmount'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

const supportedPlanPeriods = ['_30_DAYS', '_90_DAYS', '_180_DAYS', '_365_DAYS']

const CryptoInvestModal = ({ currency }: { currency: string }) => {
  const { modalHide } = useContext(GlobalContext)

  // const [flexibleInvestment, setFlexibleInvestment] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState(stakingPeriods[supportedPlanPeriods[0]])
  const [selectedPlan, setSelectedPlan] = useState(null)

  const [amountError, setAmountError] = useState(null)
  const [agree, setAgree] = useState(false)
  const [multiplier, setMultiplier] = useState(0)

  const { getCurrencyPrecision } = useCurrencies()
  const currencyDecimals = getCurrencyPrecision(currency)

  const { activeAccounts } = useMerchantAccounts({
    validAccount: (account: AccountBalanceResponse) => account.currencyCode === currency
  })
  // const { availableAccounts } = useGetActivePaymentAccountsQuery(null, {
  //   selectFromResult: ({ data, ...rest }) => {
  //     let filteredAccounts = []
  //     if (isValidArray(data) && currency !== null) {
  //       filteredAccounts = data.filter(account => account.currencyCode === currency)
  //     }
  //     return {
  //       availableAccounts: filteredAccounts,
  //       ...rest
  //     }
  //   }
  // })

  const { data: plans, isSuccess } = useGetSavingPlansForCurrencyQuery(currency)
  const [submitInvestment, { error: initiateStakingError }] = useInitiateStakingMutation()

  const formValues = useFormValues({
    amount: { type: 'string', value: '' }
  })

  const isFormDataValid = () => {
    const test = checkAmountLimits(fixFloat(formValues.getValue('amount')), selectedPlan)
    if (!test.valid) {
      setAmountError(translateError(test))
      return false
    }

    return true
  }

  const prepareToolTip = () => {
    return [
      tr(
        'FRONTEND.SAVINGS.CRYPTO.MODAL.TOOLTIP',
        'APY rate reflects the estimated annualized percentage yield and is subject to daily changes'
      )
    ]
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isFormDataValid) {
      const { id: sourceAccountId } = selectedAccount || {}
      const { id: stakingPlanId } = selectedPlan || {}
      const amount = formValues.getValue('amount')
      try {
        const result = await submitInvestment({
          sourceAccountId,
          amount,
          stakingPlanId
        }).unwrap()

        if (result) {
          modalHide()
        }
      } catch (err) {}
    }
  }

  const handlePeriodChange = (period: number) => {
    setSelectedPeriod(period)
    if (isValidArray(plans)) {
      const currentPlan = plans.find(plan => stakingPeriods[plan.duration] === period)
      setSelectedPlan({
        ...currentPlan,
        status: 'AVAILABLE'
      })
      if (currentPlan) {
        const { reward } = currentPlan
        setMultiplier((stakingPeriods[currentPlan?.duration] / 36500) * reward)
      }
    }
  }

  useEffect(() => {
    if (isValidArray(activeAccounts) && selectedAccount === null) {
      setSelectedAccount(activeAccounts[0])
    }
  }, [activeAccounts])

  useEffect(() => {
    if (isSuccess) {
      const supportedPlan = plans.find(plan => plan.duration == supportedPlanPeriods[0])
      setSelectedPlan({
        ...supportedPlan,
        status: 'AVAILABLE'
      })
      setMultiplier((stakingPeriods[supportedPlan?.duration] / 36500) * supportedPlan?.reward || 0)
    }
  }, [isSuccess])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const SelectDuration = () => (
    <Duration>
      <div className='duration-title'>{tr('FRONTEND.SAVINGS.CRYPTO.MODAL.DURATION_HEADING', 'Duration')}</div>
      <div className='duration-periods'>
        {plans &&
          plans.map(plan => {
            if (supportedPlanPeriods.find(supportedPlan => supportedPlan === plan.duration)) {
              return (
                <div
                  key={plan.id}
                  className={cn('period', {
                    active: selectedPeriod === stakingPeriods[plan.duration],
                    disabled: !plans || !plans.some(plan => stakingPeriods[plan.duration] === selectedPeriod)
                  })}
                  onClick={() => handlePeriodChange(stakingPeriods[plan.duration])}>
                  {tr(
                    `FRONTEND.SAVINGS.CRYPTO.INVEST.${plan.duration.substring(1)}`,
                    `${stakingPeriods[plan.duration]} Days`
                  )}
                </div>
              )
            }
          })}
      </div>
    </Duration>
  )

  const StakeInfo = () => {
    const { startDate, endDate, redemptionDate, reward } = selectedPlan || {}
    return (
      <>
        <InvestInfoWrapper>
          <GridTable>
            <LabelValue
              label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.START_DATE', 'Saving Start Date')}
              value={formatDate(startDate)}
              dataTest='start-date'
            />
            <LabelValue
              label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.STAKING_PERIOD', 'Saving Period')}
              value={`${selectedPeriod} ${tr('FRONTEND.SAVINGS.CRYPTO.MODAL.STAKING_PERIOD_DAYS', 'days')}`}
              dataTest='staking-period'
            />
            <LabelValue
              label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.END_DATE', 'Saving End Date')}
              value={formatDate(endDate)}
              dataTest='end-date'
            />
            <LabelValue
              label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.REDEMPTION_DATE', 'Redemption Date')}
              value={formatDate(redemptionDate)}
              dataTest='redemption-date'
            />
          </GridTable>
        </InvestInfoWrapper>
        <div className='current-apy-container'>
          <div className='current-apy'>
            {tr('FRONTEND.SAVINGS.CRYPTO.MODAL.APY', 'Est. APY %AMOUNT%').replace('%AMOUNT%', `${reward || 0} %`)}
          </div>
          <span className='account-info-icon' data-for='account-info-tooltip' data-tip={prepareToolTip()} />
        </div>
        <div className='estimated-earnings'>
          {tr('FRONTEND.SAVINGS.CRYPTO.MODAL.EST_EARNINGS', 'Est. Earnings %REWARD%').replace(
            '%REWARD%',
            `${formatFloat(
              fixFloat(formValues.getValue('amount')) * multiplier,
              currencyDecimals,
              currencyDecimals > 1 ? 2 : 0
            )} ${currency}`
          )}
        </div>
        <ReactTooltip id='account-info-tooltip' type='dark' effect='solid' place='bottom' html={true} />
      </>
    )
  }

  return (
    <InvestModalWrapper data-test='crypto-saving-modal'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='title'>{tr('FRONTEND.SAVINGS.CRYPTO.MODAL.HEADING', 'Invest')}</div>
        {/* <div className='staking-type'>
                    <RadioButtonGroup
                        margin={false}
                        options={[
                            {
                                value: true,
                                label: tr('FRONTEND.STAKING.STAKE.MODAL.LABEL_LOCKED', 'Locked Staking'),
                                dataTest: 'locked-staking-radio'
                            },
                            {
                                value: false,
                                label: tr('FRONTEND.STAKING.STAKE.MODAL.LABEL_FLEXIBLE', 'Flexible'),
                                dataTest: 'flexible-staking-radio'
                            }
                        ]}
                        type='default'
                        selected={flexibleInvestment}
                        setSelected={val => {
                            setFlexibleInvestment(!!val)
                            console.log('Selected Value', val);
                        }}
                    />
                </div> */}
        <SelectAccount
          accounts={activeAccounts}
          label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.ACCOUNT_LABEL', 'Select Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          className='mb-2'
          noShadow
          filteredCurrencies={[currency]}
        />
        <InputAmount
          label={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.AMOUNT_LABEL', 'Amount')}
          setAmount={value => formValues.setValue('amount', value)}
          error={amountError}
          amount={formValues.getValue('amount')}
          setAmountError={setAmountError}
          account={selectedAccount}
          paymentOption={selectedPlan}
          dataTest='crypto-invest-amount'
        />

        <SelectDuration />
        <StakeInfo />

        <ServiceAgreement>
          <Checkbox checked={agree} onClick={setAgree} />
          <div>
            {parse(
              tr(
                'FRONTEND.SAVINGS.CRYPTO.MODAL.AGREEMENT',
                'I have read <a>Jeton Bank Staking Service Agreement</a>'
              ).replace('<a>', "<a href='/files/Terms_and_Conditions_for_the_Saving_Account.pdf'>")
            )}
          </div>
        </ServiceAgreement>

        <div className='disclaimer'>
          **
          {tr(
            'FRONTEND.SAVINGS.CRYPTO.MODAL.DISCLAIMER',
            'If you withdraw your funds earlier than the lock-in period, you forfeit all accrued interest'
          )}
        </div>

        <ErrorDisplay error={initiateStakingError} />

        <div className='buttons-container'>
          <Button
            type='submit'
            color='primary'
            text={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.BUTTON_INVEST', 'Invest')}
            disabled={!agree || formValues.getFloat('amount') <= 0}
          />
          <Button
            color='secondary'
            text={tr('FRONTEND.SAVINGS.CRYPTO.MODAL.BUTTON_CANCEL', 'Cancel')}
            onClick={modalHide}
          />
        </div>
      </StyledForm>
    </InvestModalWrapper>
  )
}

export default CryptoInvestModal
