import { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser'
import cn from 'classnames'

import {
  GlobalContext,
  checkAmountLimits,
  fixFloat,
  formatDate,
  formatFloat,
  isValidArray,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import {
  stakingPeriods,
  useCurrencies,
  useGetSavingPlansForCurrencyQuery,
  useInitiateStakingMutation,
  useMerchantAccounts
} from 'mmfintech-backend-api'
import { Button, Checkbox, ErrorDisplay, GridTable, LabelValue } from 'mmfintech-portal-commons'

import {
  Duration,
  InvestInfoWrapper,
  InvestModalWrapper,
  ServiceAgreement,
  StyledForm
} from './styled/investModal.styled'
import InputAmount from '../../components/InputAmount'
import SelectAccount from '../../components/SelectAccount'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

const supportedPlanPeriods = ['_32_DAYS', '_90_DAYS', '_180_DAYS', '_365_DAYS']

const FiatInvestModal = ({ currency }: { currency: string }) => {
  const { modalHide } = useContext(GlobalContext)
  const [selectedPeriod, setSelectedPeriod] = useState(stakingPeriods[supportedPlanPeriods[0]])
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const [amountError, setAmountError] = useState(null)
  const [agree, setAgree] = useState(false)
  const [multiplier, setMultiplier] = useState(0)

  const { getCurrencyPrecision } = useCurrencies()
  const currencyDecimals = getCurrencyPrecision(currency)

  const { activeAccounts } = useMerchantAccounts({
    validAccount: (account: AccountBalanceResponse) => account.currencyCode === currency
  })
  // const { availableAccounts } = useGetActivePaymentAccountsQuery(null, {
  //   selectFromResult: ({ data, ...rest }) => {
  //     let filteredAccounts = []
  //     if (isValidArray(data) && currency !== null) {
  //       filteredAccounts = data.filter(account => account.currencyCode === currency)
  //     }
  //     return {
  //       availableAccounts: filteredAccounts,
  //       ...rest
  //     }
  //   }
  // })

  const { data: plans, isSuccess } = useGetSavingPlansForCurrencyQuery(currency)

  const [submitInvestment, { error: initiateStakingError }] = useInitiateStakingMutation()

  const formValues = useFormValues({
    amount: { type: 'string', value: '' }
  })

  const handlePeriodChange = (period: number) => {
    setSelectedPeriod(period)
    if (isValidArray(plans)) {
      const currentPlan = plans.find(plan => stakingPeriods[plan.duration] === period)
      setSelectedPlan({
        ...currentPlan,
        status: 'AVAILABLE'
      })
      if (currentPlan) {
        const { reward } = currentPlan
        setMultiplier((stakingPeriods[currentPlan?.duration] / 36500) * reward)
      }
    }
  }

  const isFormDataValid = () => {
    const test = checkAmountLimits(fixFloat(formValues.getValue('amount')), selectedPlan)
    if (!test.valid) {
      setAmountError(translateError(test))
      return false
    }

    return true
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isFormDataValid) {
      const { id: stakingPlanId } = selectedPlan || {}
      const { id: sourceAccountId } = selectedAccount || {}
      const amount = formValues.getValue('amount')
      try {
        const result = await submitInvestment({
          sourceAccountId,
          amount,
          stakingPlanId
        }).unwrap()
        if (result) {
          modalHide()
        }
      } catch (err) {}
    }
  }

  useEffect(() => {
    if (isValidArray(activeAccounts) && selectedAccount === null) {
      setSelectedAccount(activeAccounts[0])
    }
  }, [activeAccounts])

  useEffect(() => {
    if (isSuccess) {
      const supportedPlan = plans.find(plan => plan.duration == supportedPlanPeriods[0])
      setSelectedPlan({
        ...supportedPlan,
        status: 'AVAILABLE'
      })
      setMultiplier((stakingPeriods[supportedPlan?.duration] / 36500) * supportedPlan?.reward || 0)
    }
  }, [isSuccess])

  const SelectDuration = () => (
    <Duration>
      <div className='duration-title'>{tr('FRONTEND.SAVINGS.FIAT.MODAL.DURATION', 'Duration')}</div>
      <div className='duration-periods'>
        {plans &&
          plans.map(plan => {
            if (supportedPlanPeriods.find(supportedPlan => supportedPlan === plan.duration)) {
              return (
                <div
                  key={plan.id}
                  className={cn('period', {
                    active: selectedPeriod === stakingPeriods[plan.duration],
                    disabled: !plans || !plans.some(plan => stakingPeriods[plan.duration] === selectedPeriod)
                  })}
                  onClick={() => handlePeriodChange(stakingPeriods[plan.duration])}>
                  {tr(
                    `FRONTEND.SAVINGS.FIAT.INVEST.${plan.duration.substring(1)}`,
                    `${stakingPeriods[plan.duration]} Days`
                  )}
                </div>
              )
            }
          })}
      </div>
    </Duration>
  )

  const StakeInfo = () => {
    const { startDate, endDate, redemptionDate, reward } = selectedPlan || {}
    const earnings = fixFloat(formValues.getValue('amount')) * multiplier
    return (
      <>
        <InvestInfoWrapper>
          <GridTable>
            <LabelValue
              label={tr('FRONTEND.SAVINGS.FIAT.MODAL.START_DATE', 'Start Date')}
              value={formatDate(startDate)}
              dataTest='invest-start-date'
            />
            <LabelValue
              label={tr('FRONTEND.SAVINGS.FIAT.MODAL.END_DATE', 'End Date')}
              value={formatDate(endDate)}
              dataTest='invest-end-date'
            />
            <LabelValue
              label={tr('FRONTEND.SAVINGS.FIAT.MODAL.REDEMPTION_DATE', 'Redemption Date')}
              value={formatDate(redemptionDate)}
              dataTest='invest-redemption-date'
            />
          </GridTable>
        </InvestInfoWrapper>
        <div className='current-apy-container'>
          <div className='current-apy'>
            {tr('FRONTEND.SAVINGS.FIAT.MODAL.INTEREST', 'Interest p.a.')}: {reward || 0} %
          </div>
        </div>
        <div className='estimated-earnings'>
          {tr('FRONTEND.SAVINGS.FIAT.MODAL.EARNINGS', 'Est. Earnings')}:{' '}
          {formatFloat(earnings, currencyDecimals, currencyDecimals > 1 ? 2 : 0)} {currency}
        </div>
      </>
    )
  }

  return (
    <InvestModalWrapper data-test='fiat-invest-modal'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='title'>{tr('FRONTEND.SAVINGS.FIAT.MODAL.HEADING', 'Invest')}</div>

        <SelectAccount
          accounts={activeAccounts}
          label={tr('FRONTEND.SAVINGS.FIAT.MODAL.ACCOUNT', 'Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          className='mb-2'
          noShadow
          filteredCurrencies={[currency]}
        />
        <InputAmount
          label={tr('FRONTEND.SAVINGS.FIAT.MODAL.AMOUNT', 'Amount')}
          setAmount={value => formValues.setValue('amount', value)}
          error={amountError}
          amount={formValues.getValue('amount')}
          setAmountError={setAmountError}
          account={selectedAccount}
          paymentOption={selectedPlan}
          dataTest='saving-amount-input'
        />

        <SelectDuration />
        <StakeInfo />

        <ServiceAgreement>
          <Checkbox checked={agree} onClick={setAgree} />
          <div>
            {parse(
              tr(
                'FRONTEND.SAVINGS.FIAT.MODAL.AGREEMENT',
                'I have read <a>Jeton Bank Staking Service Agreement</a>'
              ).replace('<a>', "<a href='/files/Terms_and_Conditions_for_the_Saving_Account.pdf'>")
            )}
          </div>
        </ServiceAgreement>

        <div className='disclaimer'>
          **
          {tr(
            'FRONTEND.SAVINGS.FIAT.MODAL.DISCLAIMER',
            'If you withdraw your funds earlier than the lock-in period, you forfeit all accrued interest'
          )}
        </div>

        <ErrorDisplay error={initiateStakingError} />

        <div className='buttons-container'>
          <Button
            type='submit'
            color='primary'
            text={tr('FRONTEND.SAVINGS.FIAT.MODAL.BUTTON_INVEST', 'Invest')}
            disabled={!agree || formValues.getFloat('amount') <= 0}
          />
          <Button
            color='secondary'
            text={tr('FRONTEND.SAVINGS.FIAT.MODAL.BUTTON_CANCEL', 'Cancel')}
            onClick={modalHide}
          />
        </div>
      </StyledForm>
    </InvestModalWrapper>
  )
}

export default FiatInvestModal
