import React from 'react'

import { tr } from 'mmfintech-commons'

import { OnboardingList } from './OnboardingList'
import { Variants, motion } from 'framer-motion'
import { OnboardingProgress } from './OnboardingProgress'
import { HeaderContainer, SlidingContainer } from './OnboardingBanner.styled'

import ArrowDown from '@images/icons/onboarding/arrowdown-icon.svg?react'

export const OnboardingContainer: React.FC<{ style?: React.CSSProperties; toggleOpen: () => void }> = ({
  style,
  toggleOpen
}) => (
  <SlidingContainer initial='hidden' animate='visible' exit='hidden' variants={containerVariants} style={style}>
    <motion.div variants={contentVariants}>
      <HeaderContainer onClick={toggleOpen}>
        <div>{tr('FRONTEND.ONBOARDING.ACTIVITY', 'Onboarding activity')}</div>
        <ArrowDown />
      </HeaderContainer>
      <OnboardingProgress noMargin={false} />
      <OnboardingList />
    </motion.div>
  </SlidingContainer>
)

const containerVariants: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'anticipate'
    }
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'anticipate',
      when: 'beforeChildren' // Ensures the container scales before internal content appears
    }
  }
}

const contentVariants: Variants = {
  hidden: {
    scale: 0.8,
    opacity: 0
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.1 // Waits for container to finish scaling
    }
  }
}
