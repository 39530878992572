import { tr } from 'mmfintech-commons'

import WelcomeIcon2 from '@images/logo.svg?react'

import { WelcomeContent2Styled } from './WelcomeContent2.styled'

type TWelcomeContent2Props = {}

export const WelcomeContent2 = ({}: TWelcomeContent2Props) => {
  return (
    <WelcomeContent2Styled>
      <div className='logo'>
        <WelcomeIcon2 />
      </div>
      <div className='title'>
        {tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_2', 'We are happy that you registered with us!')}
      </div>
      <p className='description'>
        {tr(
          'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION_1',
          'The final step to easily start depositing, sending and exchanging money, is to complete the onboarding!'
        )}
      </p>
    </WelcomeContent2Styled>
  )
}
