import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import { resetEmailVerification } from 'mmfintech-backend-api'
import { LegalRequirementResponse } from 'mmfintech-commons-types'
import { Button } from 'mmfintech-portal-commons'
import { isValidArray, tr } from 'mmfintech-commons'

import { LegalRequirementsModal, Stepper } from '../../../../components'
import useOnboarding from '../../../../views/onboarding/useOnboarding'
import { WelcomeContent2 } from './WelcomeContent2'

import { ModalContainer, WelcomeModalWrapper } from './WelcomeModal.styled'

interface WelcomeModal {
  requirements?: LegalRequirementResponse[]
  currentStep?: number
}

const MAX_STEPS = [
  { step: 1, label: '' },
  { step: 2, label: '' }
]

export const WelcomeModal = ({ requirements }: WelcomeModal) => {
  const [step, setStep] = useState(isValidArray(requirements) ? 1 : 2)
  const { startOrContinueOnboarding } = useOnboarding()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetEmailVerification())
    }
  }, [])

  return (
    <ModalContainer>
      <WelcomeModalWrapper data-test='welcome-modal'>
        <div className='welcome-modal-content'>
          <h1 className='title-welcome '>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jetonbank!')}</h1>

          {isValidArray(requirements) && <Stepper className='stepper' currentStep={step} maxStep={MAX_STEPS} />}

          {step === 1 && (
            <LegalRequirementsModal isInModal={true} requirements={requirements} onAccepted={() => setStep(2)} />
          )}
          {step === 2 && <WelcomeContent2 />}

          {step === 2 && (
            <div className='buttons-wrapper'>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
                color='primary'
                onClick={() => {
                  startOrContinueOnboarding()
                  dispatch(resetEmailVerification())
                }}
              />
            </div>
          )}
        </div>
      </WelcomeModalWrapper>
    </ModalContainer>
  )
}
