import { FC } from 'react'

import styled from 'styled-components'

import { tr } from 'mmfintech-commons'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { CommonFlexRow } from '../../../components/jetonStyled'
import useOnboarding from '../../onboarding/useOnboarding'

import { MerchantAccountTypeEnum, OnboardingProviderEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import ArrowIcon from '../../../images/icons/arrow-alternative.svg?react'
import OnboardingIcon from '../../../images/icons/onboarding-icon.svg?react'

export const OnboardingBanner: FC = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const { startOrContinueOnboarding } = useOnboarding()

  if (onboardingProvider === OnboardingProviderEnum.SUMSUB) {
    if (onboardingStatus === OnboardingStatusEnum.APPROVED_EXPIRING_DOCUMENT) {
      return (
        <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
          <div className='icon'>
            <OnboardingIcon />
          </div>
          <div className='banner-text'>
            {tr(
              'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.MESSAGE',
              'Your document is expiring, upload newer one to avoid limitation of services'
            )}
          </div>
          <div className='arrow-icon'>
            <ArrowIcon />
          </div>
        </OnboardingBannerWrapper>
      )
    }

    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      if (
        onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
        onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
        onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
      ) {
        return (
          <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
            <div className='icon'>
              <OnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr(
                'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
                'Verify to get access to all features and increased payment limits.'
              )}
            </div>
            <div className='arrow-icon'>
              <ArrowIcon />
            </div>
          </OnboardingBannerWrapper>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS) {
        return (
          <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
            <div className='icon'>
              <OnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr(
                'FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.MESSAGE',
                'Complete the final step of your account activation - Additional Documents'
              )}
            </div>
            <div className='arrow-icon'>
              <ArrowIcon />
            </div>
          </OnboardingBannerWrapper>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
        return (
          <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
            <div className='icon'>
              <OnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr(
                'FRONTEND.ONBOARDING.QUESTIONNAIRE.MESSAGE',
                'Complete the questionnaire in order to proceed with the account onboarding'
              )}
            </div>
            <div className='arrow-icon'>
              <ArrowIcon />
            </div>
          </OnboardingBannerWrapper>
        )
      }

      if (
        onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
        onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
      ) {
        return (
          <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
            <div className='icon'>
              <OnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr('FRONTEND.DASHBOARD.SUMSUB.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
            </div>
          </OnboardingBannerWrapper>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
        return (
          <OnboardingBannerWrapper onClick={() => startOrContinueOnboarding()}>
            <div className='icon'>
              <OnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr(
                'FRONTEND.DASHBOARD.SUMSUB.REJECTED_MESSAGE',
                'Please contact Customer Service to get support on your account verification.'
              )}
            </div>
          </OnboardingBannerWrapper>
        )
      }
    }
  }

  return null
}

const OnboardingBannerWrapper = styled.div`
  width: 100%;
  max-width: 1370px;
  margin-top: 4rem;
  height: 8rem;

  border-radius: 2rem;
  background: #fff;

  display: grid;
  grid-template-columns: minmax(auto, 22rem) 1fr 5rem;
  grid-gap: 2rem;

  cursor: pointer;

  .icon {
    ${CommonFlexRow()}
    justify-content: center;
    align-items: center;

    border-radius: inherit;
    background-color: #e5f0ff;
  }

  .banner-text {
    color: #000;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .banner-text,
  .arrow-icon {
    align-self: center;
  }
`
