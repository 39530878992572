import styled from 'styled-components'

export const CardSettingsWrapper = styled.div`
  padding: 2.4rem;

  .top-section {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }

  .card-settings-top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-settings-title {
      font-weight: 600;
      font-size: 4rem;
      line-height: 150%;
      color: black;
    }
  }

  .core-tabs-wrapper {
    margin-top: 1.6rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.08rem;
  }

  .card-settings-item-container:not(:first-child) {
    border-top: 1px solid #ecedef;
  }

  .card-settings-bank-details {
    border-radius: 1.6rem;
    background-color: #ecedef;
    width: 100%;
    padding: 2rem 2rem 0.4rem;
    .card-settings-card-number {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.08rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.virtual {
        svg {
          filter: brightness(0) saturate(100%) invert(59%) sepia(5%) saturate(124%) hue-rotate(182deg) brightness(93%)
            contrast(89%);
        }
      }
    }

    .card-holder-name {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.92rem;
      margin-bottom: 1.6rem;
    }

    .card-bank-detail {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.92rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      .detail-label {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.68rem;
      }
    }
  }

  .card-settings-manage-section,
  .card-settings-actions-section,
  .card-settings-spend-section {
    width: 100%;
    border-radius: 1.6rem;
    background-color: white;
    min-height: 5.6rem;
    padding: 0.4rem;
  }

  .card-settings-spend-section svg {
    width: 4rem;
    height: 4rem;
  }

  .caption {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    opacity: 50%;
    margin-bottom: 0.8rem;
    &:not(:first-child) {
      margin-top: 2.4rem;
    }
  }

  .card-settings-item-wrapper {
    input:checked + i::after {
      transform: translate3d(2.2rem, 2px, 0px);
    }
  }
  .card-settings-balances {
    border-radius: 1.6rem;
    background-color: #ecedef;
    width: 100%;
    padding: 1.2rem 1.2rem 0.4rem;
    border-radius: 1.6rem;
    display: flex;
    gap: 2rem;
    padding: 2rem;
    flex-direction: column;
    .card-bank-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detail-label {
        display: flex;
        align-items: flex-start;
        gap: 0.8rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.92rem;
        .labels {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.92rem;
          }
          .subtitle {
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;
            opacity: 0.5;
          }
        }
      }
      .detail-value {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.92rem;

        &.large-value {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.08rem;
        }
      }
    }
  }
`

export const CardSettingsItemWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
  border-radius: 1.2rem;

  color: black;
  cursor: pointer;

  &:hover {
    background-color: #f7f8fa;
  }

  .card-setting-left-section {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    .card-setting-title {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.08rem;
    }

    .card-setting-subtitle {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      opacity: 50%;
    }

    .card-setting-icon {
      svg {
        vertical-align: middle;
      }
    }
  }

  input:checked + i::after {
    transform: translate3d(2.2rem, 2px, 0px);
  }
`
