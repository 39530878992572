import { tr } from 'mmfintech-commons'

import { DisplayActions } from './DisplayActions'

import KybIcon from '@images/icons/onboarding/kyb-icon.svg?react'
import AgreementIcon from '@images/icons/onboarding/agreement-icon.svg?react'
import JetonBankIcon from '@images/icons/onboarding/jetonbank-icon.svg?react'
import VerificationIcon from '@images/icons/onboarding/verification-icon.svg?react'

export const OnboardingList = () => {
  const onboardingSteps = [
    {
      Icon: JetonBankIcon,
      title: tr('FRONTEND.ONBOARDING.STEPS.CREATE_ACCOUNT', 'Create company account')
    },
    {
      Icon: KybIcon,
      title: tr('FRONTEND.ONBOARDING.STEPS.QUESTIONNAIRE_TITLE', 'Provide company details'),
      content: tr('FRONTEND.ONBOARDING.STEPS.QUESTIONNAIRE', 'Complete the KYB questionnaire'),
      step: 'kyb-questionnaire'
    },
    {
      Icon: VerificationIcon,
      title: tr('FRONTEND.ONBOARDING.STEPS.VERIFICATION_TITLE', 'Verify company details'),
      content: tr('FRONTEND.ONBOARDING.STEPS.VERIFICATION', 'Complete your account verification'),
      step: 'sumsub-onboarding'
    },
    {
      Icon: AgreementIcon,
      title: tr('FRONTEND.ONBOARDING.STEPS.AGREEMENT_TITLE', 'Business agreement'),
      content: tr('FRONTEND.ONBOARDING.STEPS.AGREEMENT', 'Download and fill in our business agreement'),
      step: 'additional-documents'
    }
  ]

  return (
    <ul>
      {onboardingSteps.map(({ Icon, title, content, step }, index) => (
        <li key={index}>
          <Icon />
          <div className='content'>
            <p>{title}</p>
            {content && <span>{content}</span>}
          </div>
          <DisplayActions step={step} />
        </li>
      ))}
    </ul>
  )
}
