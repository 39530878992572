import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { CheckmarkIcon, ChevronDownIcon } from '../../icons'
import { MenuContainer, MenuDropDown, MenuWrapper } from './MerchantSwitchMenu.styled'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  api,
  paths,
  selectMerchant,
  useGetMerchantsForSwitchQuery,
  useSwitchMerchantMutation
} from 'mmfintech-backend-api'

export const MerchantSwitchMenu = ({ className = undefined }) => {
  const { data: merchant } = useAppSelector(selectMerchant)
  const { data: merchants } = useGetMerchantsForSwitchQuery(null)

  const [switchMerchant, { isLoading: switchMerchantLoading }] = useSwitchMerchantMutation()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleLanguageClick = async (merchantId: number) => {
    hideDropDown()
    if (!switchMerchantLoading && merchant?.merchantId !== merchantId) {
      try {
        await switchMerchant({ merchantId }).unwrap()
        history.push(paths.dashboard())
        dispatch(api.util.resetApiState())
      } catch (_error) {}
    }
  }

  if (Array.isArray(merchants) && merchants.length > 1) {
    return (
      <MenuWrapper data-test='merchant-menu-wrapper' className={className}>
        <MenuContainer className={cn({ opened: visible })} onClick={toggleDropDown}>
          {merchant ? (
            <div>
              <span className='icon-letter active'>{merchant.name.substring(0, 1)}</span>
              <span>{merchant.name}</span>
            </div>
          ) : (
            tr('FRONTEND.HEADER.SWITCH_MERCHANT', 'Switch to')
          )}
          {/*<span className='arrow'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>*/}
          <ChevronDownIcon />
        </MenuContainer>
        <MenuDropDown className={cn({ opened: visible })} data-test='merchant-menu-content'>
          <ul data-test='language-menu-list'>
            {Array.isArray(merchants) &&
              merchants.map(v => (
                <li key={v.merchantId} onClick={() => handleLanguageClick(v.merchantId)} data-test='merchant-menu-item'>
                  <div>
                    <span className={cn('icon-letter', { active: v.merchantId === merchant?.merchantId })}>
                      {v.name.substring(0, 1)}
                    </span>
                    {v.name}
                  </div>
                  {v.merchantId === merchant?.merchantId ? <CheckmarkIcon /> : null}
                </li>
              ))}
          </ul>
        </MenuDropDown>
      </MenuWrapper>
    )
  }

  return null
}
