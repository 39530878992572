import React, { useEffect } from 'react'

import { useCurrencies } from 'mmfintech-backend-api'
import { checkSingleValue, formatFloat, isValidFunction, tr } from 'mmfintech-commons'

import {
  InputAmountElement,
  InputAmountError,
  InputAmountHeader,
  InputAmountLabel,
  InputAmountLimits,
  InputAmountStatic,
  InputAmountWrapper,
  MaxAmountLabel
} from './styled/inputAmount.styled'

import {
  AccountBalanceResponse,
  PaymentOption,
  PaymentOptionResponse,
  PaymentOptionStatusEnum
} from 'mmfintech-commons-types'

import CalendarIcon from '../images/icons/arrow_icon.svg?react'

interface InputAmountProps {
  label?: string
  amount?: string
  account: AccountBalanceResponse
  setAmount?: (amount: string) => void
  paymentOption?: PaymentOption | PaymentOptionResponse
  placeholder?: string
  error?: string
  setAmountError?: (arg: any) => void
  dataTest: string
  inputMaxAmount?: number
  disabled?: boolean
  enableMaxButton?: boolean
}

function InputAmount(props: InputAmountProps) {
  const {
    inputMaxAmount,
    label,
    amount,
    account,
    setAmount,
    paymentOption,
    error,
    setAmountError,
    placeholder,
    dataTest,
    disabled = false,
    enableMaxButton = false,
    ...rest
  } = props
  const { currencyCode } = account || {}

  const { getCurrencyPrecision } = useCurrencies()
  const decimals = getCurrencyPrecision(currencyCode)
  const { status, maxAmount: maxPaymentAmount, minAmount } = paymentOption || {}
  const maxAmount = inputMaxAmount || maxPaymentAmount

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidFunction(setAmount)) {
      setAmount(checkSingleValue(e.target.value, { validation: 'float', decimals }))
      setAmountError && setAmountError(null)
    }
  }

  useEffect(() => {
    if (currencyCode && amount && setAmount) {
      setAmount(checkSingleValue(amount, { validation: 'float', decimals }))
    }
  }, [currencyCode])

  return (
    <InputAmountWrapper {...rest}>
      <InputAmountHeader>
        <InputAmountLabel>{label}</InputAmountLabel>
        {status === PaymentOptionStatusEnum.AVAILABLE && (
          <InputAmountLimits>
            {minAmount > 0 && (
              <span>
                {tr('FRONTEND.MONEY_INPUT.LABEL_MIN', 'Min')}: {formatFloat(minAmount, decimals)}
              </span>
            )}
            {maxAmount > 0 && (
              <span>
                {tr('FRONTEND.MONEY_INPUT.LABEL_MAX', 'Max')}: {formatFloat(maxAmount, decimals)}
              </span>
            )}
          </InputAmountLimits>
        )}
      </InputAmountHeader>

      {isValidFunction(setAmount) ? (
        <InputAmountElement
          type='text'
          value={amount || ''}
          onChange={handleAmountChange}
          placeholder={placeholder || '0'}
          maxLength={16}
          data-test={dataTest}
          aria-label='amount'
          disabled={disabled}
        />
      ) : (
        <InputAmountStatic data-test={dataTest}>{amount || 0}</InputAmountStatic>
      )}
      {maxAmount > 0 && enableMaxButton && (
        <MaxAmountLabel
          onClick={() => {
            setAmount(checkSingleValue(maxAmount, { validation: 'float', decimals }))
          }}>
          <CalendarIcon />
          <span>{`${tr('FRONTEND.MONEY_INPUT.LABEL_ADD_MAX', 'Add Max')}`}</span>
        </MaxAmountLabel>
      )}

      {error && error?.length && <InputAmountError>{error}</InputAmountError>}
    </InputAmountWrapper>
  )
}

export default InputAmount
