import { Fragment, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'

import {
  selectCurrentUserRole,
  useApproveExpenseRequestMutation,
  useCancelExpenseRequestMutation,
  useLazyFetchExpenseRequestsQuery,
  useMerchantAccounts,
  useRejectExpenseRequestMutation
} from 'mmfintech-backend-api'
import { DataTable, NoElementsMessage, Pagination, Preloader } from 'mmfintech-portal-commons'
import { GlobalContext, isValidArray, OtpContext, tr, usePagination } from 'mmfintech-commons'

import { breakpoints } from '../../constants'
import { useAppSelector } from '../../hooks'
import { SuggestTransferModal } from '../banking/SuggestTransferModal'
import { RequestTableRow } from './RequestsTableRow'
import { LimitsWrapper } from './styles/Limits.styled'

import { AccountBalanceResponse, CustomerRoleEnum, ExpenseView } from 'mmfintech-commons-types'

import SortArrowDown from '../../images/icons/sort-arrow-down.svg?react'
import SortArrowUp from '../../images/icons/sort-arrow-up.svg?react'

const { Table, Head, Row, HeadCell, Body } = DataTable

const HEAD_CELLS = [
  { title: 'Name', sort: 'name' },
  { title: 'Description', sort: 'description' },
  { title: 'Date', sort: 'createdOn' },
  { title: 'Amount', sort: 'amount' },
  { title: 'Recipient', className: 'small' },
  {},
  { title: 'Status', sort: 'pending' }
]

function RequestsTable() {
  const { modalShow }: any = useContext(GlobalContext)
  const { setOtpOnSuccess, setOtpOnError }: any = useContext(OtpContext)

  const customerRole = useAppSelector(selectCurrentUserRole)
  const { activeAccounts } = useMerchantAccounts()

  const [cancelRequest] = useCancelExpenseRequestMutation()
  const [rejectRequest] = useRejectExpenseRequestMutation()
  const [approveRequest] = useApproveExpenseRequestMutation()

  const [fetchExpenseRequests, { data: expenseRequests, isFetching }] = useLazyFetchExpenseRequestsQuery()

  const pagination = usePagination({
    rowsPerPage: 5,
    reload: async (params: any, onSuccess: (response: any) => void) => {
      try {
        const response = await fetchExpenseRequests({
          ...params,
          sort: params?.sort || 'pending-desc,requestId-desc'
        }).unwrap()

        onSuccess(response)
      } catch {}
    }
  })

  const [isSortAsc, setIsSortAsc] = useState(false)
  const [sort, setSort] = useState('pending-desc,requestId')

  const reloadWithSort = (cusSort?: string) =>
    pagination.reload({
      size: 5,
      page: pagination?.currentPage,
      sort: `${cusSort || sort}-${isSortAsc ? 'desc' : 'asc'}`
    })

  const reloadWithCurrentSort = () =>
    pagination.reload({
      size: 5,
      page: pagination?.currentPage,
      sort: `${sort}-${isSortAsc ? 'asc' : 'desc'}`
    })

  const handleSort = (e: any, sort: string) => {
    e.stopPropagation()
    setSort(sort)
    reloadWithSort(sort)
    setIsSortAsc(!isSortAsc)
  }

  function SortArrow({ headCellName }: { headCellName: string }) {
    return sort === headCellName ? (
      <span className='sort-icons'>{isSortAsc ? <SortArrowUp fill='white' /> : <SortArrowDown fill='white' />}</span>
    ) : null
  }

  const handleReject = (id: any) => {
    if (customerRole === CustomerRoleEnum.STAFF) {
      cancelRequest({ id: id }).unwrap().then(onSuccess).catch(onError)
    } else {
      rejectRequest({ id: id }).unwrap().then(onSuccess).catch(onError)
    }
  }

  const handleApprove = (id: any, sourceAccountId: any) => {
    setOtpOnSuccess(() => () => {
      reloadWithCurrentSort()
      toast.success(
        tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.SUCCESS.MESSAGE', 'Request successfully approved')
      )
    })
    setOtpOnError(() => (error: any) => {
      if (error?.data?.status === 'MERCHANTS.ERROR.NOT_ENOUGH_BALANCE') {
        const account = isValidArray(activeAccounts)
          ? activeAccounts?.find((a: AccountBalanceResponse) => a?.id === sourceAccountId)
          : null

        modalShow({
          options: {
            size: 'auto'
          },
          content: <SuggestTransferModal account={account} />
        })
      }
    })
    approveRequest({ id })
  }

  const onError = (err: any) => {
    toast.error(err?.error)
  }

  const onSuccess = () => {
    const message =
      customerRole === CustomerRoleEnum.STAFF
        ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.CANCEL.MESSAGE', 'Request canceled')
        : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.REJECT.MESSAGE', 'Request declined')
    toast.success(message)
    pagination.reload()
  }

  return (
    <LimitsWrapper>
      {isFetching ? (
        <Preloader />
      ) : isValidArray(expenseRequests?.content) ? (
        <>
          <Table scale2={`${breakpoints.xl}px`} skipHover>
            <Head>
              <Row className='headRow'>
                {HEAD_CELLS.map((cell, index: number) => (
                  <Fragment key={`cell-${index}`}>
                    {!cell?.sort && !cell?.title ? <HeadCell /> : null}
                    {!cell?.sort && cell?.title ? (
                      <HeadCell className={cell?.className}>
                        {tr(`FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE_COL.${cell.title}`, `${cell.title}`)}
                      </HeadCell>
                    ) : null}
                    {cell?.sort && cell?.title ? (
                      <HeadCell
                        className={`${sort === cell.sort ? 'activeSortCell' : ''} ${cell?.className ?? ''}`}
                        onClick={(e: any) => handleSort(e, cell.sort)}>
                        <SortArrow headCellName={cell.sort} />
                        {tr(`FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE_COL.${cell.title}`, `${cell.title}`)}
                      </HeadCell>
                    ) : null}
                  </Fragment>
                ))}
              </Row>
            </Head>

            <Body>
              {expenseRequests?.content.map((transaction: ExpenseView, index: number) => (
                <RequestTableRow
                  transaction={transaction}
                  key={`transaction-${index}`}
                  handleApprove={handleApprove}
                  handleReject={handleReject}
                />
              ))}
            </Body>
          </Table>
          <Pagination {...pagination.register()} sort={`${sort}-${!isSortAsc ? 'desc' : 'asc'}`} />
        </>
      ) : (
        <NoElementsMessage
          message={tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.NO_REQUESTS.TITLE', 'No expense requests yet')}
        />
      )}
    </LimitsWrapper>
  )
}

export default RequestsTable
