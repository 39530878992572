import styled from 'styled-components'

interface StatusBannerProps {
  status: string
}

function StatusBanner({ status }: StatusBannerProps) {
  return (
    <StatusBannerWrapper className={`status-box ${status.toLowerCase()?.replace(' ', '-')}`}>
      {status}
    </StatusBannerWrapper>
  )
}

export default StatusBanner

const StatusBannerWrapper = styled.div`
  &.status-box {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    text-align: center;
    padding: 0.7rem 1rem;
    border-radius: 5.6rem;

    max-width: 16rem;

    &.pending,
    &.scheduled,
    &.in-progress,
    &.initial,
    &.buy {
      /* background: #fffbeb; */
      border: 1px solid #ffce32;
      color: #ffce32;
    }
    &.processed,
    &.onboarded,
    &.active,
    &.paid,
    &.sell {
      /* background: #e3f2de; */
      border: 1px solid #44cc00;
      color: #44cc00;
    }
    &.failed,
    &.cancelled,
    &.rejected,
    &.pending-cancellation,
    &.not_activated,
    &.locked_activation {
      /* background: #f5e3e3; */
      border: 1px solid #ff3131;
      color: #ff3131;
    }
    &.not-verified,
    &.not-started,
    &.under-review {
      /* background: #edf0f1; */
      border: 1px solid #a7b3be;
      color: #85a0ad;
    }
    &.frozen,
    &.frozen_by_superuser {
      border: 1px solid #40bdfc;
      color: #40bdfc;
    }
  }
`
