import { memo, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'

import cn from 'classnames'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'

import { tr } from 'mmfintech-commons'

import { SelectInputWrapper } from './selectInput.styled'

function SelectInput(props) {
  const {
    id,
    name,
    async,
    error,
    label,
    theme,
    value,
    options,
    onChange,
    dataTest,
    disabled,
    required,
    className,
    creatable,
    hideLabel,
    applyStyles,
    loadOptions,
    placeholder,
    menuPosition,
    hideRequired,
    disableSearch,
    hideErrorLine,
    menuPlacement,
    indicatorIcon,
    loadingMessage,
    onCreateOption,
    preselectValue,
    parentClassName,
    noOptionsMessage,
    formatCreateLabel,
    valueIsObject
  } = props
  const [selectedOption, setSelectedOption] = useState(null)
  const [translatedOptions, setTranslatedOptions] = useState([])

  const getPlaceholder = () => {
    if (placeholder?.length) return placeholder
    return (required && !hideRequired ? '* ' : '') + label
  }

  const handleCreate = value => {
    onCreateOption && onCreateOption(value)
    setSelectedOption({ label: value, value })
  }

  const handleChange = selectedOption => {
    onChange && onChange(name, selectedOption.value)
    setSelectedOption(selectedOption)
  }

  const isLabelVisible = () => {
    if (label?.length) {
      return value != null && value.toString().length > 0
    }
    return false
  }

  const prepareIndicatorIcon = () => {
    if (indicatorIcon) {
      // noinspection JSUnusedGlobalSymbols
      return {
        DropdownIndicator: props => (
          <components.DropdownIndicator {...props}>{indicatorIcon}</components.DropdownIndicator>
        )
      }
    }
    return null
  }

  useEffect(() => {
    const filteredOptions = options?.map(option => ({
      ...option,
      label: option?.localizationKey ? tr(option?.localizationKey) : option.label
    }))

    setTranslatedOptions(filteredOptions)
  }, [options])

  const defaultStyles = {
    menu: provided => ({
      ...provided,
      fontSize: '1.8rem',
      border: 'none !important',
      backgroundColor: '#ffffff !important'
    }),
    control: provided => ({
      ...provided,
      height: '5rem',
      borderRadius: '3px',
      borderColor: error && error.length > 0 ? 'transparent' : '#a3b8c2',
      borderBottomColor: error && error.length > 0 ? '#ff3131' : '#a3b8c2',
      boxShadow: 'none',
      backgroundColor: '#ffffff'
    }),
    placeholder: provided => ({
      ...provided,
      color: '#a3b8c2',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: 'normal'
    }),
    valueContainer: provided => ({
      ...provided,
      color: '#131E3D',
      fontSize: '1.8rem',
      fontWeight: '500',
      padding: '2px 0'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#131E3D'
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#ffffff' : '#131E3D',
      backgroundColor: isFocused ? '#131E3D' : 'transparent'
    })
  }

  useEffect(() => {
    if (!async && !creatable) {
      const option = translatedOptions?.find(item => item.value === value || item.value === value?.toString())
      setSelectedOption(option ?? null)
    }
    // eslint-disable-next-line
  }, [value, translatedOptions, selectedOption])

  useEffect(() => {
    if (preselectValue) {
      if (Array.isArray(options)) {
        const find = options.find(v => v.value.toString() === preselectValue.toString())
        if (find) {
          handleChange(find)
        }
      }
    }
    // eslint-disable-next-line
  }, [preselectValue, options])

  const addDataTest = (Component, dataTest) =>
    function (props) {
      return <Component {...props} innerProps={{ ...props.innerProps, 'data-test': dataTest }} />
    }

  return (
    <SelectInputWrapper className={cn('select-wrapper', parentClassName, theme)}>
      {!hideLabel ? (
        <label>
          {isLabelVisible() && required && !hideRequired && <span className='asterisk'>*</span>}
          {isLabelVisible() && <span>{label}</span>}
          {!isLabelVisible() && ' '}
        </label>
      ) : null}

      {async ? (
        <AsyncSelect
          id={id || name}
          name={name}
          isDisabled={disabled}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          loadOptions={loadOptions}
          loadingMessage={() => loadingMessage}
          defaultOptions
          value={selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null)
          }}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      ) : creatable ? (
        <CreatableSelect
          id={id || name}
          name={name}
          isDisabled={disabled}
          isSearchable={!disableSearch}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          options={translatedOptions}
          value={selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          onCreateOption={handleCreate}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null),
            ...prepareIndicatorIcon()
          }}
          formatCreateLabel={formatCreateLabel}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      ) : (
        <Select
          id={id || name}
          name={name}
          isDisabled={disabled}
          isSearchable={!disableSearch}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          options={translatedOptions}
          value={valueIsObject ? selectedOption || { value, label: value } : selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null),
            ...prepareIndicatorIcon()
          }}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      )}

      {!hideErrorLine || error ? <span className='error-message'>{error}</span> : null}
    </SelectInputWrapper>
  )
}

export default memo(SelectInput, (prevProps, nextProps) => isEqual(prevProps, nextProps))

SelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  applyStyles: PropTypes.object,
  placeholder: PropTypes.string,
  disableSearch: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideRequired: PropTypes.bool,
  hideErrorLine: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  required: PropTypes.bool.isRequired
}

SelectInput.defaultProps = {
  required: false
}
