import React from 'react'
import { Link } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths, useLoginQry } from 'mmfintech-backend-api'

import { JetonLogo } from '../../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  LoginContainer,
  LoginContent,
  LoginContentWrapper,
  LoginWrapper,
  SignupLinkWrapper,
  StyledForm
} from './Login.styled'

export const Login = () => {
  const { formValues, login, loginError, loginFetching, resetLogin } = useLoginQry()

  const handleFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void login()
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <JetonLogo />

        <LoginContentWrapper>
          <LoginContent>
            <div className='heading'>{tr('FRONTEND.LOGIN.TITLE', 'Sign In')}</div>

            <StyledForm noValidate onSubmit={handleFormSubmit}>
              <Input
                id='email'
                type='email'
                data-test='email'
                label={tr('FRONTEND.LOGIN.EMAIL', 'E-mail Address')}
                {...formValues.registerInput('email', resetLogin)}
                disabled={loginFetching}
                hideErrorLine
                hideRequired
              />

              <Input
                id='password'
                type='password'
                data-test='password'
                label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
                {...formValues.registerInput('password', resetLogin)}
                disabled={loginFetching}
                autoComplete='off'
                hideErrorLine
                hideRequired
              />

              <div className='link-wrap' data-test='forgot-password'>
                <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
              </div>

              <ErrorDisplay error={loginError} />

              <Button
                type='submit'
                text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
                loading={loginFetching}
                data-test='button-submit'
              />
            </StyledForm>
          </LoginContent>

          <SignupLinkWrapper>
            {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}
            <Link to={paths.signUp()} data-test='sign-up'>
              <span>{tr('FRONTEND.LOGIN.SIGNUP_BUTTON', 'Sign up')} →</span>
            </Link>
          </SignupLinkWrapper>
        </LoginContentWrapper>

        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}
