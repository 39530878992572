import { useContext, useEffect } from 'react'

import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector,
  useGetPendingLegalRequirementsQuery,
  useGetSumSubStatusQuery
} from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, useWindowSize } from 'mmfintech-commons'

import { LegalRequirementsModal } from '../../components'
import { OnboardingBanner } from './elements/OnboardingBanner'
import { WelcomeModal } from './elements/WelcomeModal'
import { DashboardStaff } from './DashboardStaff'
import { DashboardAdmin } from './DashboardAdmin'
import { breakpoints } from '../../constants'

import { ColumnTwoSectionContainer } from '../../components/jetonStyled'
import { DashboardContainer } from './Dashboard.styled'

export const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const customerRole = useAppSelector(selectCurrentUserRole)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)

  useGetSumSubStatusQuery(null)

  const {
    data: legalRequirements,
    isFetching: legalRequirementsFetching,
    isLoading: legalRequirementsLoading
  } = useGetPendingLegalRequirementsQuery(null)

  const [width] = useWindowSize()

  useEffect(() => {
    if (legalRequirementsFetching || legalRequirementsLoading) return null

    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal requirements={legalRequirements} />
      })
    } else if (!legalRequirementsFetching && isValidArray(legalRequirements)) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnEscape: false,
          closeOnClickOutside: false
        },
        content: <LegalRequirementsModal requirements={legalRequirements} />
      })
    }
  }, [legalRequirements])

  return (
    <DashboardContainer>
      {width > breakpoints.xl && <OnboardingBanner />}

      <ColumnTwoSectionContainer size='xl' additionalStyles={{ gap: '5rem', padding: '2rem 0' }}>
        {isOwnerOrAdministrator(customerRole) ? <DashboardAdmin /> : <DashboardStaff />}
      </ColumnTwoSectionContainer>
    </DashboardContainer>
  )
}
