import { useContext } from 'react'

import { MobileBanner } from './OnboardingBanner.styled'
import { GlobalContext } from 'mmfintech-commons'
import { OnboardingProgress } from './OnboardingProgress'
import { OnboardingContainer } from './OnboardingContainer'

import ArrowIcon from '@images/icons/arrow-outlined.svg?react'

export const OnboardingBannerMobile = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const handleOpenModal = () =>
    modalShow({
      content: <OnboardingContainer toggleOpen={modalHide} />
    })

  return (
    <MobileBanner onClick={handleOpenModal}>
      <OnboardingProgress noMargin={true} isBanner={true} />
      <div className='right'>
        <div className='banner-container'>
          <p>{'Onboarding' /* todo: localize */}</p>
          <div className='ml-2'>
            <ArrowIcon />
          </div>
        </div>
        <span>{'Complete your account activation' /* todo: localize */}</span>
      </div>
    </MobileBanner>
  )
}
