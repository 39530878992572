import { useEffect, useState } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

const getFraction = (progress: number): string => {
  const numerator = progress / 25
  const denominator = 4

  return `${numerator}/${denominator}`
}

export const useOnboardingProgress = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus } = merchant || {}
  const [currentProgress, setCurrentProgress] = useState<25 | 50 | 75 | 100>(25)

  useEffect(() => {
    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      switch (onboardingStatus) {
        case OnboardingStatusEnum.IN_PROGRESS:
        case OnboardingStatusEnum.UNDER_REVIEW:
        case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
          setCurrentProgress(50)
          break
        case OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS:
        case OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL:
        case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
          setCurrentProgress(75)
          break
        case OnboardingStatusEnum.APPROVED:
          setCurrentProgress(100)
          break
      }
    } else {
      setCurrentProgress(100)
    }
  }, [onboardingStatus])

  return {
    currentProgress,
    progressFraction: getFraction(currentProgress)
  }
}
