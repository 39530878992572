import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import settings from '../../settings'

import { breakpoints } from '../../constants'
import { isMobileDevice, tr, useWindowSize } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import useHideHeaders from '../../hooks/useHideHeaders'
import HamburgerMenu from './HamburgerMenu'
import ConversationsBanner from '../../views/dashboard/Conversation/ConversationsBanner'
import { HeaderIcons } from './HeaderIcons'
import { PaymentButtons } from '../PaymentButtons'
import { OnboardingBanner } from '../../views/onboarding'
import { Hamburger, HamburgerIcon, HeaderContainer, HeaderInnerContainer, HeaderWrapper } from './Header.styled'

import LogoImage from '@images/logo.svg'
import { LoginStatusEnum } from 'mmfintech-commons-types'

const MAX_MOBILE_WIDTH = breakpoints.xl

export const Header = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)
  const { data: merchant } = useGetMerchantQuery()
  const userStatus = useAuth()

  const [opened, setOpened] = useState(false)

  const visible = useHideHeaders()

  const { apiEnabled } = merchant || {}
  const [width] = useWindowSize()

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)
  const isHamburgerVisible = () => width <= MAX_MOBILE_WIDTH
  const hideMenu = () => opened && setOpened(false)

  const BrandLogoImage = () => <img src={LogoImage} alt='Logo' style={{ padding: '2rem' }} />
  const BrandLogo = () => {
    return isMobileDevice() ? (
      <BrandLogoImage />
    ) : isLoggedIn() ? (
      <Link className='brand-logo' to={paths.dashboard()}>
        <BrandLogoImage />
      </Link>
    ) : (
      <a className='brand-logo' href={settings.landingPageUrl}>
        <BrandLogoImage />
      </a>
    )
  }
  const UserMenuItems: React.FC<{ className?: string; onClick: () => void }> = ({ className, onClick }) => {
    return (
      <>
        {isHamburgerVisible() && (
          <NavLink
            to={paths.profile()}
            onClick={() => {
              typeof onClick === 'function' && onClick()
              hideMenu()
            }}
            className={className}>
            {tr('FRONTEND.HEADER.PROFILE', 'Profile')}
          </NavLink>
        )}
        <NavLink
          to={paths.security()}
          onClick={() => {
            typeof onClick === 'function' && onClick()
            hideMenu()
          }}
          className={className}>
          {tr('FRONTEND.HEADER.SECURITY', 'Security')}
        </NavLink>
        {isOwnerOrAdministrator(customerRole) && (
          <NavLink
            to={paths.userManagement()}
            onClick={() => {
              typeof onClick === 'function' && onClick()
              hideMenu()
            }}
            className={className}>
            {tr('FRONTEND.HEADER.USERS', 'Users')}
          </NavLink>
        )}
        <a
          href={settings.customerServiceUrl}
          rel='noopener noreferrer'
          target='_blank'
          onClick={() => {
            typeof onClick === 'function' && onClick()
            hideMenu()
          }}
          className={className}>
          {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer Service')}
        </a>
        {isDevelopersMenuVisible() && (
          <NavLink
            to={paths.developer()}
            onClick={() => {
              typeof onClick === 'function' && onClick()
              hideMenu()
            }}
            className={className}>
            {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
          </NavLink>
        )}
      </>
    )
  }

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper visible={visible}>
      {opened && <div className='overlay' onClick={hideMenu} />}
      <HeaderContainer>
        <BrandLogo />
        {isHamburgerVisible() ? (
          <Hamburger>
            <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
              <span className='menu-icon' />
            </HamburgerIcon>

            <HamburgerMenu
              Logo={BrandLogo}
              Items={UserMenuItems}
              opened={opened}
              hideMenu={() => setOpened(false)}
              maxWidth={MAX_MOBILE_WIDTH}
            />
          </Hamburger>
        ) : (
          <PaymentButtons />
        )}
      </HeaderContainer>

      {isLoggedIn() ? (
        <HeaderInnerContainer bordered>
          <OnboardingBanner />
          <ConversationsBanner />
          {!isHamburgerVisible() && <HeaderIcons UserMenu={UserMenuItems} maxWidth={MAX_MOBILE_WIDTH} />}
        </HeaderInnerContainer>
      ) : null}
    </HeaderWrapper>
  )
}
