import { useContext, useEffect } from 'react'

import cn from 'classnames'

import { globalSettings, useAuth, useOtpQry } from 'mmfintech-backend-api'
import { formatPhoneNumber, isValidArray, isValidFunction, OtpContext, tr, useValidUntilTimer } from 'mmfintech-commons'

import { GoogleAuthenticator } from '../GoogleAuthenticator'
import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { Countdown, ResendMessage, VerificationContainer } from './Otp.styled'

import { ChallengePurposeEnum, LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

import EmailIcon from '@images/icons/email-icon.svg?react'
import GaIcon from '@images/icons/ga-icon.svg?react'
import SmartIdIcon from '@images/icons/smart-id-icon.svg?react'
import SMSIcon from '@images/icons/sms-icon.svg?react'

type OtpProps = {
  onCancel?: () => void
}

const CHANGE_2FA_OPTIONS = [
  ChallengePurposeEnum.CHANGE_2FA,
  ChallengePurposeEnum.CHANGE_2FA_TOTP,
  ChallengePurposeEnum.CHANGE_2FA_PHONE
]

export const Otp = ({ onCancel }: OtpProps) => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    availableTwoFactorTypes,
    handleCodeChanged,
    handleChangeOtpType,
    handleCancel,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry({
    autoSubmit: true
  })

  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)

  const userStatus = useAuth()

  const timer = useValidUntilTimer()

  const { sentTo, twoFactorType, challengePurpose, totpSecretKeyUri } = challenge || {}

  useEffect(() => {
    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [])

  const alternativeOtpMethods = (method: string) => {
    switch (method) {
      case TwoFactorTypeEnum.SMS:
        return (
          <div className='alternative-method-wrapper'>
            <SMSIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMS.LABEL', 'SMS')}</span>
          </div>
        )
      case TwoFactorTypeEnum.SMART_ID:
        return (
          <div className='alternative-method-wrapper'>
            <SmartIdIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMART_ID.LABEL', 'Smart ID')}</span>
          </div>
        )
      case TwoFactorTypeEnum.EMAIL:
        return (
          <div className='alternative-method-wrapper'>
            <EmailIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.EMAIL.LABEL', 'Email')}</span>
          </div>
        )
      case TwoFactorTypeEnum.TOTP:
        return (
          <div className='alternative-method-wrapper'>
            <GaIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.GA.LABEL', 'GA code')}</span>
          </div>
        )
      default:
        return null
    }
  }

  const internalCancel = () => {
    isValidFunction(onCancel) && onCancel()
    handleCancel()
  }

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }
    if (twoFactorType === TwoFactorTypeEnum.TOTP) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_TOTP', 'Google Authenticator verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_TOTP', 'Google Authenticator confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='title'>{prepareTitle()}</div>
      {totpSecretKeyUri ? (
        <GoogleAuthenticator totpSecretKeyUri={totpSecretKeyUri} />
      ) : (
        <div className='subtitle'>
          {twoFactorType === TwoFactorTypeEnum.TOTP
            ? tr('FRONTEND.VERIFY_OTP.TOTP_TARGET_LABEL', 'Please, enter the verification code:')
            : tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
          <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
        </div>
      )}

      <ErrorDisplay error={verifyOtpError} />

      {resendOtpFetching || verifyOtpFetching ? (
        <Spinner />
      ) : (
        <>
          <input
            id='otp'
            type='tel'
            className={cn('input', {
              complete: code?.length === codeSize,
              wrong: code?.length === codeSize && verifyOtpError != null
            })}
            autoComplete='off'
            onChange={e => handleCodeChanged(e.target.value)}
            value={code}
            maxLength={codeSize}
            autoFocus
            placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            data-test='tel-input'
          />

          {challengePurpose !== ChallengePurposeEnum.CHANGE_2FA_TOTP && twoFactorType !== TwoFactorTypeEnum.TOTP && (
            <ResendMessage>
              {attempts < globalSettings.otpMaxAttempts ? (
                <>
                  {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
                  <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
                    {resendTimer.expired
                      ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                      : resendTimer.remainingTime}
                  </span>
                </>
              ) : (
                tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
              )}
            </ResendMessage>
          )}

          {isValidArray(availableTwoFactorTypes?.filter(type => type !== twoFactorType)) &&
            !CHANGE_2FA_OPTIONS.includes(challengePurpose as ChallengePurposeEnum) && (
              <>
                <div className='otp-modal-methods-title'>
                  {tr('FRONTEND.VERIFY_OTP.ALTERNATIVE_RETRY', "Haven't received it? Retry via:")}
                </div>

                <div className={cn('otp-modal-methods-actions', { disable: !resendTimer.expired })}>
                  {attempts < globalSettings.otpMaxAttempts ? (
                    <>
                      {availableTwoFactorTypes
                        .filter(type => type !== twoFactorType)
                        .map(type => {
                          return (
                            <div
                              key={type}
                              className='otp-alternative-method'
                              onClick={() => resendTimer.expired && handleChangeOtpType({ type })}>
                              {alternativeOtpMethods(type)}
                            </div>
                          )
                        })}
                    </>
                  ) : (
                    tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
                  )}
                </div>
              </>
            )}

          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={resendOtpFetching || verifyOtpFetching}
            onClick={internalCancel}
            data-test='button-cancel'
          />
        </>
      )}

      {attempts >= globalSettings.otpMaxAttempts || resendOtpFetching || verifyOtpFetching ? null : (
        <Countdown isExpired={timer.expired}>{timer.formattedTime}</Countdown>
      )}
    </VerificationContainer>
  )
}
